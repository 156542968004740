<template>
  <a-modal
    v-model:visible="localOpen"
    :confirm-loading="isDeleting"
    :okType="'danger'"
    title="Confirm delete"
    :destroyOnClose="true"
    :footer="null"
    @cancel="handleCancel"
  >
    <!-- <span v-if="deletingUser && deletingUser.username" class="delete-text">
      Do you want to delete <b>{{ deletingUser.username }}</b> account ?
    </span> -->
    <div class="delete-modal-content">
      <div class="ask-text">
        Do you want to these <b>{{ deletingUsers.length }}</b> account ?
      </div>
      <div class="action-button-modal">
        <a-button size="large" type="default" class="cancel-button" :outlined="true" @click="handleCancel">
          Cancel
        </a-button>
        <a-button size="large" type="default" class="delete-button" @click="handleConfirmDelete">Delete</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

import api from '@/services/api';

export default {
  emits: ['deleted'],
  setup(props, { emit }) {
    const store = useStore();
    const localOpen = ref(false);
    const storeOpen = computed(() => store.state.user.deletingMultipleModalOpen);

    const deletingUsers = computed(() => store.state.user.deletingUsers);

    watch(
      () => storeOpen.value,
      () => {
        localOpen.value = storeOpen.value;
        isDeleting.value = false;
      }
    );

    const isDeleting = ref(false);

    const handleConfirmDelete = async () => {
      isDeleting.value = true;
      const idList = deletingUsers.value;
      // console.log(idList);

      const result = await api.bulkDeleteUser({
        userId: idList,
      });
      if (result && result.message) {
        emit('deleted', true);
      } else {
        emit('deleted', false);
      }
    };

    const handleCancel = () => {
      store.dispatch('user/cancelDeletingUsers');
    };

    return {
      localOpen,
      isDeleting,
      deletingUsers,
      handleConfirmDelete,
      handleCancel,
    };
  },
};
</script>

<style lang="scss" scoped>
.delete-modal-content {
  .ask-text {
    font-size: 15px;
    line-height: 20px;
    color: #5a5f7d;
  }

  .action-button-modal {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    .ant-btn {
      font-weight: 400;
      font-size: 14px;
      height: 44px;
    }
    .delete-button {
      background: #ff4d4f;
      color: #fff;
    }
    .cancel-button {
      background: #f8f9fb;
      color: #5a5f7d;
      margin-right: 8px;
      border: 1px solid #f1f2f6;
    }
  }
}
</style>
