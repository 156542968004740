<template>
  <div class="user-manager">
    <div class="title-bar">
      <div class="left-side">
        <div class="page-title">
          <span class="title-text"> User Setting</span>
        </div>
        <!-- <a-divider type="vertical" /> -->
        <div class="user-counter">{{ totalUser }} Users</div>
        <div class="search-wrapper">
          <!-- <a-input v-model:value="searchInput" placeholder="Search by Name"></a-input> -->
          <a-input v-model:value="searchInput" placeholder="Search by Name" class="search-input-wrapper">
            <template #prefix>
              <FeatherIcon type="search" size="16" color="#ADB4D2" />
            </template>
          </a-input>
        </div>
      </div>
      <div class="right-side">
        <div class="search-wrapper-tablet">
          <!-- <a-input v-model:value="searchInput" placeholder="Search by Name"></a-input> -->
          <a-input v-model:value="searchInput" placeholder="Search by Name" class="search-input-wrapper">
            <template #prefix>
              <FeatherIcon type="search" size="16" color="#ADB4D2" />
            </template>
          </a-input>
        </div>
        <a-button type="primary" @click="goAddUser">
          <FeatherIcon type="plus" />
          Add New
        </a-button>
      </div>
    </div>
    <div class="list-wrapper">
      <UsersLists
        :loading="loading"
        :tableData="filteredUserList"
        :actions="actions"
        :isTimeFromNow="true"
        @toggleAction="onToggleAction"
      ></UsersLists>
    </div>
    <a-card class="table-wrapper">
      <TableSetting
        :rowKey="'id'"
        :isRowSelection="true"
        :isActionDropDown="true"
        :isTotalActive="true"
        :isTimeFromNow="true"
        :tableData="filteredUserList"
        :columns_props="columnConfig"
        :actions="actions"
        :loading="loading"
        :pagination="{
          showQuickJumper: false,
          showSizeChanger: true,
          total: filteredUserList.length,
          showTotal: () => '',
        }"
        :locale="locale"
        @toggleAction="onToggleAction"
      />
    </a-card>
    <DeleteUserModal @deleted="onUserDeleted" />
    <DeleteMultipleUserModal @deleted="onMultipleUserDeleted" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';

import TableSetting from '@/components/Table/TableSetting.vue';
import UsersLists from '@/components/Lists/UsersLists.vue';
import DeleteUserModal from './DeleteUserModal.vue';
import DeleteMultipleUserModal from './DeleteMultipleUserModal.vue';

import api from '@/services/api';
import helper from '@/services/helper';

export default {
  components: {
    TableSetting,
    DeleteUserModal,
    DeleteMultipleUserModal,
    UsersLists,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const loading = ref(true);
    const searchInput = ref('');
    const userList = ref([]);

    const columnConfig = [
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        slots: { customRender: 'username' },
        sorter: helper.getTableSortFn('string', 'username'),
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        slots: { customRender: 'role' },
        sorter: helper.getTableSortFn('string', 'role'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        slots: { customRender: 'status' },
        sorter: helper.getTableSortFn('string', 'status'),
      },
      {
        title: 'Last Active',
        dataIndex: 'status',
        key: 'lastActiveAt',
        slots: { customRender: 'last_active' },
        sorter: helper.getTableSortFn('date-x', 'lastActiveAt'),
      },
      {
        title: 'Notification',
        key: 'notification',
        slots: { customRender: 'notification' },
        filters: [
          {
            text: 'Report',
            value: 'isAcceptReport',
          },
          {
            text: 'Keyword Alert',
            value: 'isAcceptSpecialKeyword',
          },
          // {
          //   text: 'Anomaly Alert',
          //   value: 'isAcceptAnomaly',
          // },
          {
            text: 'Setting Alert',
            value: 'isAcceptSettingChange',
          },
        ],
        onFilter: (value, record) => {
          return record[value];
        },
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        slots: { customRender: 'updated_at' },
        sorter: helper.getTableSortFn('date-x', 'updatedAt'),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
      },
    ];

    const actions = [
      { name: 'Edit', value: 'edit', icon: 'edit' },
      { name: 'Delete', value: 'delete', icon: 'trash-2' },
    ];

    const filteredUserList = computed(() => {
      const users = userList.value;
      if (users) {
        const searchTerm = searchInput.value.trim();
        if (searchTerm) {
          return users.filter((u) => u.username.includes(searchTerm));
        } else {
          return users;
        }
      }
      return [];
    });

    const totalUser = computed(() => {
      if (filteredUserList.value) {
        return helper.numeral(filteredUserList.value.length);
      }
      return 0;
    });

    const getUserList = async () => {
      const result = await api.getUserList().catch(() => {});
      if (result && result.message) {
        userList.value = result.message;
      }
      loading.value = false;
    };

    const goAddUser = () => {
      router.push({ name: 'user-add' });
    };

    const onToggleAction = (actionObj) => {
      const { action, record } = actionObj;
      if (action === 'edit') {
        store.dispatch('user/setEditingUser', record).then(() => {
          router.push({ name: 'user-edit' });
          // console.log(store.state.user.editingUser);
        });
      } else if (action === 'delete') {
        store.dispatch('user/setDeletingUser', record).then(() => {});
      } else if (action === 'deleteMultiple') {
        store.dispatch('user/setDeletingUsers', record).then(() => {});
      } else if (action === 'open_link') {
        //
      }
    };

    const onUserDeleted = (username) => {
      if (username) {
        notification.success({
          message: 'Success',
          description: `User ${username} removed.`,
        });
        getUserList();
      } else {
        notification.error({
          message: 'Error',
          description: `User ${username} remove failed.`,
        });
      }
      store.dispatch('user/cancelDeletingUser');
    };

    const onMultipleUserDeleted = (state) => {
      if (state) {
        notification.success({
          message: 'Success',
          description: 'Users removed.',
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Users remove failed.',
        });
      }
      store.dispatch('user/cancelDeletingUsers');
      getUserList();
    };

    onMounted(() => {
      getUserList();
    });

    let locale = {
      filterReset: 'Reset',
      filterConfirm: 'Apply',
    };

    return {
      searchInput,
      filteredUserList,
      totalUser,
      goAddUser,
      columnConfig,
      actions,
      onToggleAction,
      onUserDeleted,
      onMultipleUserDeleted,
      locale,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-manager {
  padding: 30px;
  overflow: auto;
  height: 100%;
  width: 100%;
  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1050px) {
      display: block;
    }
    .left-side {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      column-gap: 24px;
      .page-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        color: #272b41;
        border-right: 1px solid #e3e6ef;
        @media screen and (max-width: 1083px) {
          border-right: none;
        }
        // margin-right: 24px;
        .title-text {
          padding-right: 24px;
          @media screen and (max-width: 820px) {
            padding-right: 0;
          }
        }
      }
      .user-counter {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        color: #5a5f7d;
        margin: 0;
      }

      .search-wrapper {
        display: block;
        @media screen and (max-width: 1083px) {
          display: none;
        }
        .search-input-wrapper {
          border-radius: 29px;
          width: 331px;
          height: 40px;
          border: 1px solid #f4f5f7;
          background: #ffffff;
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 24px;
      @media screen and (max-width: 1050px) {
        margin-top: 8px;
      }
      .search-wrapper-tablet {
        display: none;
        @media screen and (max-width: 1083px) {
          display: block;
        }
        .search-input-wrapper {
          border-radius: 29px;
          width: 331px;
          height: 40px;
          border: 1px solid #f4f5f7;
          background: #ffffff;
          @media screen and (max-width: 593px) {
            width: 301px;
          }
          @media screen and (max-width: 515px) {
            width: 231px;
          }
        }
      }
      .ant-btn {
        @media screen and (max-width: 750px) {
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }
  }
  .table-wrapper {
    margin-top: 24px;
    padding: 27px 20px 24px;
    display: block;
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
  .list-wrapper {
    display: none;
    @media screen and (max-width: 750px) {
      display: block;
    }
  }
}
</style>
<style scoped>
:deep(.table-wrapper.ant-card .ant-card-body) {
  padding: 0;
}
@media screen and (max-width: 767px) {
  :deep(.table-wrapper.ant-card .ant-card-body) {
    padding: 0 !important;
  }
}
</style>
