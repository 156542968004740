<template>
  <a-card v-for="user in innerTableData" :key="user.id" class="card-wrapper">
    <table class="table">
      <tbody>
        <tr class="item-row">
          <td class="middle">
            <div class="d-flex info-row">
              <div class="user-info">
                <div class="username">
                  {{ user.username }}
                </div>
                <div class="account">
                  {{ user.email || 'No email' }}
                </div>
                <div v-if="user.role === 'accountmanager'" class="role">Account Manager</div>
                <div v-else-if="user.role === 'domadmin'" class="role">DOM Admin</div>
                <div v-else class="role">{{ user.role }}</div>
              </div>
              <div class="status-info">
                <div class="menu-item">
                  <a-dropdown :placement="'bottomRight'" trigger="click">
                    <div class="user-menu-button" @click.stop="">
                      <FeatherIcon type="more-horizontal" size="18" />
                    </div>
                    <template #overlay>
                      <a-menu class="user-influencer-menu">
                        <a-menu-item class="user-menu-item-wrapper" @click="toggleAction('edit', user)">
                          <a class="user-menu-item">
                            <FeatherIcon type="edit" />
                            Edit
                          </a>
                        </a-menu-item>
                        <a-menu-item class="user-menu-item-wrapper" @click="toggleAction('delete', user)">
                          <a class="user-menu-item">
                            <FeatherIcon type="trash-2" />
                            Delete
                          </a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
                <div class="status-tag">
                  <span class="inner-tag" :class="`status-${user.status}`"> {{ user.status }} </span>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr class="item-row">
          <td colspan="4">
            <div class="pl-3 pr-3">
              <div class="action-list small">
                <div class="action-row">
                  <div class="action-title">Notification</div>
                  <div class="action-value">
                    <a-tooltip title="Report" placement="topLeft" color="#fff">
                      <a-button class="circular noti-icon" :class="{ active: user.isAcceptReport }">
                        <img :src="require('@/assets/images/icon/bar-chart.svg')" />
                      </a-button>
                    </a-tooltip>
                    <a-tooltip title="Keyword Alert" placement="topLeft" color="#fff">
                      <a-button class="circular noti-icon" :class="{ active: user.isAcceptSpecialKeyword }">
                        <FeatherIcon type="key" size="10" />
                      </a-button>
                    </a-tooltip>
                    <a-tooltip title="Setting Alert" placement="topLeft" color="#fff">
                      <a-button class="circular noti-icon" :class="{ active: user.isAcceptSettingChange }">
                        <FeatherIcon type="settings" size="10" />
                      </a-button>
                    </a-tooltip>
                  </div>
                </div>
                <div class="action-row">
                  <div class="action-title">Last Active</div>
                  <div class="action-value">
                    <span v-if="isTimeFromNow">{{ convertToTimeFromNow(user.lastActiveAt) || '-' }}</span>
                    <span v-else>{{ dateStr(user.lastActiveAt) || '-' }}</span>
                  </div>
                </div>
                <div class="action-row">
                  <div class="action-title">Updated At</div>
                  <div class="action-value">
                    <span v-if="isTimeFromNow">{{ convertToTimeFromNow(user.updated_at || user.updatedAt) }}</span>
                    <span v-else>{{ dateStr(user.updated_at || user.updatedAt) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </a-card>
</template>

<script>
import { ref, toRefs, watch, onMounted } from 'vue';
import helper from '@/services/helper';
export default {
  name: 'UsersLists',
  props: {
    loading: Boolean,
    tableData: Array,
    actions: Array,
    isTimeFromNow: Boolean,
    locale: Object,
  },
  setup(props, { emit }) {
    const rowSelected = ref([]);
    const innerTableData = ref([]);
    const { tableData } = toRefs(props);
    let current = ref(1);

    const toggleAction = (action, record) => {
      // console.log('toggleAction', action, record);
      // emit
      emit('toggleAction', {
        action,
        record,
      });
    };

    const deleteMultiple = () => {
      toggleAction('deleteMultiple', rowSelected.value);
    };

    const handleChange = (pagination) => {
      current.value = pagination.current;
      emit('change', pagination);
    };

    const dateStr = (date) => {
      if (date) {
        return helper.formatDate(date, 'MMM D, YYYY');
      }
      return '';
    };

    const convertToTimeFromNow = (date) => {
      if (date) {
        return helper.timeFromNow(date);
      }
      return '';
    };

    const sentimentIcon = (score) => {
      if (score != null) {
        if (score > 1) return 'smile';
        if (score < -1) return 'frown';
        return 'meh';
      }
      return 'x-circle';
    };

    const sentimentClass = (score) => {
      let result = 'neutral-bg';
      if (score != null) {
        if (score > 1) result = 'positive-bg';
        else if (score < -1) result = 'negative-bg';
        else result = 'neutral-bg';
      }
      return result;
    };

    watch(tableData, () => {
      innerTableData.value = [];
      rowSelected.value = [];
      setTimeout(() => {
        innerTableData.value = [...tableData.value];
      }, 200);
    });

    onMounted(() => {
      innerTableData.value = [...tableData.value];
    });

    return {
      handleChange,
      dateStr,
      convertToTimeFromNow,
      sentimentIcon,
      sentimentClass,
      rowSelected,
      toggleAction,
      innerTableData,
      deleteMultiple,
      current,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-border {
  border-bottom: 1px solid #f1f2f6;
}
.action-list {
  padding-top: 12px;
  padding-bottom: 16px;
  .title {
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
  }
  .action-row {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 8px;
    .action-title {
      color: #9299b8;
      font-size: 12px;
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
    .action-value {
      color: #5a5f7d;
    }
  }
}
.card-wrapper {
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-radius: 10px;
  border-top: 8px solid #f0f2f5;
  border-bottom: 8px solid #f0f2f5;
  .table {
    width: 100%;
    tbody {
      color: #5a5f7d;
      .item-row {
        td {
          padding: 16px 16px 0;
          &.middle {
            vertical-align: middle;
          }
        }
        .info-row {
          justify-content: space-between;
          .user-info {
            margin-left: 16px;
            max-width: 200px;
            .username {
              line-height: 14px;
              color: #272b41;
              font-weight: 500;
              font-size: 14px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .account {
              margin-bottom: 12px;
              margin-top: 4px;
              line-height: 16px;
              color: #9299b8;
              font-weight: 400;
              font-size: 11px;
            }
            .role {
              margin-top: 4px;
              line-height: 16px;
              color: #9299b8;
              font-weight: 400;
              font-size: 12px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .status-info {
            text-align: right;
            .status-tag {
              .inner-tag {
                padding: 3px 12px;
                border-radius: 15px;
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
                &.status-Active,
                &.status-active {
                  color: #20c997;
                  background: #e9faf5;
                }
                &.status-Inactive,
                &.status-inactive {
                  color: #fa8b0c;
                  background: #fff3e7;
                }
              }
            }
            .menu-item {
              margin-bottom: 8px;
              color: #9299b8;
            }
          }
        }
        .action-list {
          border-top: 1px solid #f1f2f6;
          font-size: 13px;
          .action-title {
            color: #9299b8;
            font-size: 14px;
            display: flex;
            align-items: center;
            i {
              margin-right: 5px;
            }
          }
          .action-value {
            color: #5a5f7d;
            display: flex;
            flex-direction: row;
            row-gap: 0;
            column-gap: 4px;
            .noti-icon {
              width: 23px;
              height: 30px;
              border-radius: 50%;
              background: rgba(173, 180, 210, 0.1);
              color: #adb4d2;
              cursor: auto;
              img {
                width: 13px;
                height: 13px;
              }
              &.active {
                background: rgba(9, 124, 255, 0.1);
                img {
                  filter: invert(60%) sepia(90%) saturate(1200%) hue-rotate(196deg) brightness(100%) contrast(119%);
                }
                i {
                  color: #097cff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.text-center {
  margin: 36px 0 0 0;
}

@media only screen and (max-width: 580px) {
  .text-center {
    margin: 23px 0 0 0;
  }
}
</style>
<style scoped>
:deep(.ant-card-body) {
  padding: 0 !important;
}
</style>
